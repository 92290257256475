.cursor-clickable {
  cursor: pointer;
}

.cursor-outbound {
  cursor: alias;
}

.cursor-sortable {
  cursor: ns-resize;
}

.cursor-help {
  cursor: help;
}
